import { Link } from 'gatsby';
import React from 'react';
import { connect } from 'react-redux';
import EnsoSteps from '../components/enso-steps';
import Layout from '../components/layout';
import { API_URL } from '../lib/utils';

const OAUTH_URL = `${API_URL}/oauth2/auth/`;

const ConnectionsPage = ({
    redirectUri,
    orgState: { sourceOrg, targetOrg} = {},
}) => (
    <Layout requireLogin className="connect-page container">
        <div className="stepper">
            <EnsoSteps step={1} />
        </div>
        <div className="row mb-5">
            <div className="text-center col-md-6">
                <SalesforceLogin
                    orgLabel="source"
                    orgState={sourceOrg}
                    authUrl={`${OAUTH_URL}?redirectUri=${redirectUri}`}
                />
            </div>
            <div className="text-center col-md-6">
                <SalesforceLogin
                    orgLabel="target"
                    orgState={targetOrg}
                    authUrl={
                        `${OAUTH_URL}?targetOrg=1&redirectUri=${redirectUri}`
                    }
                />
            </div>
        </div>
        <div className="btns-primary">
            <Link className="btn btn-secondary btn-large" to="/registration/">
                Back
            </Link>
            {/* TODO: Only show next button when both orgs are logged in. */}
            <Link
                className="btn btn-primary btn-large float-right"
                to="/confirmation/"
            >
                Next
            </Link>
        </div>
    </Layout>
);

const SalesforceLogin = ({ orgLabel, orgState, authUrl }) => {
    const loginButtonConfig = orgState
        ? { className: 'btn-secondary', text: 'Log in Again' }
        : { className: 'btn-primary', text: 'Log in' };
    return (
        <>
            <h2>
                {orgLabel.charAt(0).toUpperCase() + orgLabel.slice(1)} Org
            </h2>
            <p className="mb-3">
                {
                    orgState ? <>
                        Username: {orgState.username}<br />
                        Instance: {orgState.instanceUrl}
                    </> : <>
                        Please log in with your {orgLabel} org credentials
                    </>
                }
            </p>
            <p className="btn-text">
                <a
                    className={`btn btn-large ${loginButtonConfig.className}`}
                    href={authUrl}
                >
                    {loginButtonConfig.text}
                </a>
            </p>
        </>
    );
};

export default connect(
    ({ enso: { orgState } }) => ({ orgState }),
    null,
    (stateProps, _, ownProps) => ({
        ...stateProps,
        ...ownProps,
        redirectUri: window.encodeURIComponent(ownProps.location.href),
    }),
)(ConnectionsPage);
